@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

h1,
h2,
h3,
p,
a,
ul,
li,
input,
button,
textarea,
select,
option,
table,
th,
td {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style-type: none;
  color: #000;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  text-transform: capitalize;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;
}

h3 {
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: capitalize;
}

header {
  background: #000;
}
header > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header ul li a {
  color: #fff;
}
header .contact-links,
header .social-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.icon-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

nav {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 0.5s ease-in-out;
}
nav > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav > div .hamburger {
  cursor: pointer;
}
nav > div .hamburger:hover {
  color: #03a6a6;
}
nav > div .navlinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  transition: all 0.5s ease-in-out;
}
nav > div .navlinks li a {
  text-transform: uppercase;
}
nav > div .navlinks li a.active {
  color: #03a6a6;
  font-weight: bold;
}
nav > div .navlinks .close-btn {
  display: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
nav > div .navlinks .close-btn:hover {
  color: #03a6a6;
}

.button {
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  background: #03a6a6;
  color: #fff;
  transition: all 0.5s ease-in-out;
  text-transform: capitalize;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.button.active {
  background: #000;
  color: #fff !important;
}
.button:hover {
  background: #000;
}

.banner {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5019607843), rgba(0, 0, 0, 0.7333333333)), url(../images/banner.jpg) center no-repeat;
  background-size: cover;
  min-height: 500px;
  display: grid;
  place-items: center;
}
.banner h1,
.banner p {
  color: #fff;
}

.glass {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.icon {
  font-size: 2rem;
}

.logo {
  width: 200px;
}

footer {
  background: #000;
}
footer p,
footer a {
  color: #fff;
  transition: all 0.5s ease-in-out;
}
footer a:hover {
  color: #03a6a6;
}
footer .icon-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.title .bull {
  width: 50px;
  height: 50px;
}
.title h2 {
  text-transform: uppercase;
}

.two-background {
  position: relative;
  height: auto;
}
.two-background::before {
  content: "";
  width: 50%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.two-background::after {
  content: "";
  width: 50%;
  height: 100%;
  background: #03a6a6;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.two-background .philosophy-mission {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  justify-content: space-between;
}
.two-background .philosophy-mission h2,
.two-background .philosophy-mission p,
.two-background .philosophy-mission li {
  color: #fff;
}
.two-background .philosophy-mission .philosophy {
  background: #000;
  padding: 50px 50px 50px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
.two-background .philosophy-mission .philosophy ul li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url(../images/checked.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
  filter: invert(1);
}
.two-background .philosophy-mission .mission {
  background: #03a6a6;
  padding: 50px 10px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
.two-background .philosophy-mission .mission ul li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url(../images/checked.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
  filter: invert(1);
}

.input {
  border: none;
  outline: none;
  width: 100%;
  border-bottom: 2px solid #000;
  position: relative;
  padding: 5px 0;
}

.contact-form label {
  display: block;
}

textarea.input {
  height: 100px;
  resize: none;
  overflow-y: auto;
}

iframe {
  width: 100%;
  height: 300px;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: 2px solid #000;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.button-grid > div {
  text-align: center;
}

.facebook {
  color: #4e69a2;
}

.twitter {
  color: #33aaf3;
}

.whatsapp {
  color: #3ad775;
}

.box-grid {
  -moz-columns: 3;
       columns: 3;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.box-grid > div {
  width: 100%;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
  overflow: auto;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: 1px solid #000;
}
.box-grid > div:hover {
  background: #03a6a6;
  border: 1px solid #03a6a6;
}
.box-grid > div:hover p, .box-grid > div:hover h3 {
  color: #fff;
}
.box-grid > div > * {
  cursor: pointer;
}
.box-grid > div h3 {
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-grid > div p {
  margin-bottom: 10px;
  transition: all 0.5s ease-in-out;
}
.box-grid > div p:last-child {
  margin-bottom: 0;
}
.box-grid > div::-webkit-scrollbar {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=checkbox],
input[type=radio] {
  accent-color: #03a6a6;
}

.primary-box {
  background: #03a6a6;
  border-radius: 5px;
  padding: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.primary-box p {
  color: #fff;
}

.rupee-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-bottom: 2px solid #000;
  padding: 5px 0;
}
.rupee-input input {
  border: none;
  outline: none;
  width: 100%;
}

.payment-plan-grid label {
  height: 48px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-circle:before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid #03a6a6;
  border-color: #03a6a6 transparent #03a6a6 transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.black-button {
  background: #000 !important;
}

.contact-form label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#container.drishy {
  padding: 10px 0;
}/*# sourceMappingURL=style.css.map */