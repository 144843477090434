@media screen and (max-width: 1200px) {
  .button-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 992px) {
  .navlinks {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(200%);
    background: #fff;
    height: 100vh;
    z-index: 10;
    flex-direction: column;
    width: 280px;
    justify-content: center !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  .navlinks.active {
    transform: translateX(0);
  }
  .navlinks .close-btn {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .logo-bar {
    width: 100%;
  }
  .button-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .box-grid {
    -moz-columns: 2;
         columns: 2;
  }
}
@media screen and (max-width: 768px) {
  .payment-plan-grid label {
    height: auto;
  }
  .box-grid {
    -moz-columns: 1;
         columns: 1;
  }
}
@media screen and (max-width: 576px) {
  .philosophy-mission {
    grid-template-columns: 1fr !important;
  }
  .philosophy-mission .philosophy,
  .philosophy-mission .mission {
    padding: 50px 10px !important;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 450px) {
  .button-grid {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=responsive.css.map */