@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
$primary: #03a6a6;
$radius: 5px;
$transition: all 0.5s ease-in-out;
$shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

html {
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}
img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
h1,
h2,
h3,
p,
a,
ul,
li,
input,
button,
textarea,
select,
option,
table,
th,
td {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style-type: none;
  color: #000;
}
h1 {
  font-size: 2rem;
  font-weight: bold;
  text-transform: capitalize;
}
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;
}
h3 {
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: capitalize;
}
header {
  background: #000;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul {
    li {
      a {
        color: #fff;
      }
    }
  }

  .contact-links,
  .social-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }
}

.icon-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

nav {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: $transition;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hamburger {
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }

    .navlinks {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      transition: $transition;

      li a {
        text-transform: uppercase;

        &.active {
          color: $primary;
          font-weight: bold;
        }
      }
      .close-btn {
        display: none;
        cursor: pointer;
        transition: $transition;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.button {
  padding: 10px;
  border: none;
  outline: none;
  border-radius: $radius;
  background: $primary;
  color: #fff;
  transition: $transition;
  text-transform: capitalize;
  box-shadow: $shadow;

  &.active {
    background: #000;
    color: #fff !important;
  }

  &:hover {
    background: #000;
  }
}

.banner {
  background: linear-gradient(90deg, #00000080, #000000bb),
    url(../images/banner.jpg) center no-repeat;
  background-size: cover;
  min-height: 500px;
  display: grid;
  place-items: center;

  h1,
  p {
    color: #fff;
  }
}

.glass {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: $shadow;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.icon {
  font-size: 2rem;
}

.logo {
  width: 200px;
}
footer {
  background: #000;
  p,
  a {
    color: #fff;
    transition: $transition;
  }

  a:hover {
    color: $primary;
  }

  .icon-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 10px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;

  .bull {
    width: 50px;
    height: 50px;
  }

  h2 {
    text-transform: uppercase;
  }
}

.two-background {
  position: relative;
  height: auto;

  &::before {
    content: "";
    width: 50%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &::after {
    content: "";
    width: 50%;
    height: 100%;
    background: $primary;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .philosophy-mission {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    justify-content: space-between;
    h2,
    p,
    li {
      color: #fff;
    }

    .philosophy {
      background: #000;
      padding: 50px 50px 50px 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;

      ul {
        li {
          &::before {
            content: "";
            display: inline-block;
            height: 20px;
            width: 20px;
            background-image: url(../images/checked.png);
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 10px;
            filter: invert(1);
          }
        }
      }
    }
    .mission {
      background: $primary;
      padding: 50px 10px 50px 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;

      ul {
        li {
          &::before {
            content: "";
            display: inline-block;
            height: 20px;
            width: 20px;
            background-image: url(../images/checked.png);
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 10px;
            filter: invert(1);
          }
        }
      }
    }
  }
}

.input {
  border: none;
  outline: none;
  width: 100%;
  border-bottom: 2px solid #000;
  position: relative;
  padding: 5px 0;
}

.contact-form {
  label {
    display: block;
  }
}

textarea.input {
  height: 100px;
  resize: none;
  overflow-y: auto;
}

iframe {
  width: 100%;
  height: 300px;
  border: 2px solid transparent;
  border-radius: $radius;
  transition: $transition;
  box-shadow: $shadow;
  border: 2px solid #000;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  & > div {
    text-align: center;
  }
}

.facebook {
  color: #4e69a2;
}
.twitter {
  color: #33aaf3;
}
.whatsapp {
  color: #3ad775;
}

.box-grid {
  columns: 3;
  column-gap: 8px;

  & > div {
    width: 100%;
    break-inside: avoid;
    overflow: auto;
    cursor: pointer;
    transition: $transition;
    box-shadow: $shadow;
    border: 1px solid #000;

    &:hover {
      background: $primary;
      border: 1px solid $primary;
    }

    &:hover p,
    &:hover h3 {
      color: #fff;
    }

    & > * {
      cursor: pointer;
    }

    h3 {
      transition: $transition;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      margin-bottom: 10px;
      transition: $transition;
    }
    p:last-child {
      margin-bottom: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="checkbox"],
input[type="radio"] {
  accent-color: $primary;
}
.primary-box {
  background: $primary;
  border-radius: $radius;
  padding: 8px;
  box-shadow: $shadow;

  p {
    color: #fff;
  }
}
.rupee-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-bottom: 2px solid #000;
  padding: 5px 0;

  input {
    border: none;
    outline: none;
    width: 100%;
  }
}
.payment-plan-grid {
  label {
    height: 48px;
  }
}

//
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-circle:before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid $primary;
  border-color: $primary transparent $primary transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.black-button {
  background: #000 !important;
}

.contact-form label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#container.drishy {
  padding: 10px 0;
}
